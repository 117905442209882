/** @jsxRuntime classic */
/* @jsx jsx */
import { jsx } from '@emotion/react';
import { useState, useEffect, useRef } from 'react';
import { useTransition, animated } from 'react-spring';

const Teana = props => {
  const teanaRef = useRef();
  const [showTeana, setShowTeana] = useState(false);

  const transition = useTransition(showTeana, {
    from: { y: -100, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: -100, opacity: 0 },
  });

  useEffect(() => {
    window.addEventListener('scroll', e => handleScroll(e));
  }, []);

  const handleScroll = e => {
    const positions = teanaRef.current.getBoundingClientRect();
    if (positions.top === 0) {
      setShowTeana(true);
    } else {
      setShowTeana(false);
    }
  };

  const teanaStyle = {
    position: 'sticky',
    top: 0,
    zIndex: 9999,
    div: {
      display: 'flex',
      justifyContent: 'center',
      width: '100vw',
      background: 'linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,1));',
      height: '150px',
      position: 'absolute',
      top: 0,
    },
    img: {
      marginTop: '62px',
      position: 'absolute',
      filter: 'invert(100%)',
      width: '130px',
      cursor: 'pointer',
    },
  };

  return (
    <div
      css={teanaStyle}
      ref={teanaRef}
      onClick={() => props.scrollTo('landing')}
    >
      {transition(
        (style, item) =>
          item
            ? <animated.div
              style={{ ...style, display: 'flex', justifyContent: 'center' }}
            >
              <div>
                <img alt="teana" src="/pictures/teana_text.svg" />
              </div>
            </animated.div>
            : ''
      )}
    </div>
  );
};

export default Teana;
