/** @jsxRuntime classic */
/* @jsx jsx */
import { jsx } from '@emotion/react'
import facepaint from 'facepaint'
import { useState, useRef } from 'react'
import Marquee from 'react-fast-marquee'

import song1 from '../songs/1.wav'
import song2 from '../songs/2.wav'
import song3 from '../songs/3.wav'
import song4 from '../songs/4.wav'
import song5 from '../songs/5.wav'

// RESPONSIVENESS SETTINGS
const breakpoints = [600, 1100]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`))

export default function BottomHeader(props) {
  const [playSpotify, setPlaySpotify] = useState(false)

  const songs = [song1, song2, song3, song4, song5]
  const titles = [
    ' BELLEMERE - TEANA ( LUCID FAUNA, 2017 ) ',
    ' FIRST TIME - TEANA ( LUCID FAUNA, 2017 ) ',
    ' SUMMER DREAMS - TEANA ( LUCID FAUNA, 2017 ) ',
    ' ON THE WAY - TEANA ( LUCID FAUNA, 2017 ) ',
    ' BELLEMERE ATLANTIDA VERSION - TEANA / ( LIVE RECORDING, 2019 ) ',
  ]
  const [songCount, setSongsCount] = useState(0)

  const audioPlayerRef = useRef()
  let audio = document.getElementById('audio')

  if (audio)
    audio.onended = () => {
      pauseAudio()
      setSongsCount(songCount === songs.length ? 0 : songCount + 1)
      start()
    }

  const start = () => {
    setPlaySpotify(true)
    audioPlayerRef.current.play()
  }

  const pauseAudio = () => {
    audioPlayerRef.current.pause()
    setPlaySpotify(false)
  }

  return (
    <div css={bottomHeaderStyle}>
      <audio
        src={songs[songCount]}
        preload='metadata'
        ref={audioPlayerRef}
        id='audio'
      />
      <div className='playWrapper' onClick={playSpotify ? pauseAudio : start}>
        {playSpotify ? (
          <img
            className='pause'
            alt='pause'
            src='https://img.icons8.com/external-kiranshastry-solid-kiranshastry/64/000000/external-pause-multimedia-kiranshastry-solid-kiranshastry.png'
          />
        ) : (
          <img
            className='play'
            src='https://img.icons8.com/ios-glyphs/30/000000/play--v1.png'
            alt='play'
          />
        )}
      </div>
      {/* <div > */}
      <Marquee className='border' gradient={false}>
        {titles[songCount]}{' '}
      </Marquee>
      {/* </div> */}
      <div className='border socials'>
        <a
          href='https://www.facebook.com/teanaband'
          target='_blank'
          rel='noreferrer'
        >
          <img src='/pictures/facebook.svg' alt='facebook' />
        </a>
        <a
          href='https://www.twitter.com/teanaband'
          target='_blank'
          rel='noreferrer'
        >
          <img src='/pictures/twitter.svg' alt='twitter' />
        </a>
        <a
          href='https://open.spotify.com/artist/3ceUUedlLTXTSc4TcPpWxm?si=Bwm9k6i8SHSHB10ommaZOw'
          target='_blank'
          rel='noreferrer'
        >
          <img src='/pictures/spotify.svg' alt='spotify' />
        </a>
        <a
          href='https://www.youtube.com/channel/UCwsF5C1hQfBSdjxaVOoCY8A'
          target='_blank'
          rel='noreferrer'
        >
          <img src='/pictures/youtube.svg' alt='youtube' />
        </a>
        <a
          href='https://www.instagram.com/teanaband'
          target='_blank'
          rel='noreferrer'
        >
          <img src='/pictures/instagram.svg' alt='instagram' />
        </a>
      </div>
    </div>
  )
}

const bottomHeaderStyle = mq({
  position: 'fixed',
  bottom: 0,
  zIndex: 10000,
  backgroundColor: 'black',
  maxWidth: '100vw',
  width: '100vw',
  height: '50px',
  borderTop: '1px solid white',
  display: 'grid',
  gridTemplateColumns: ['1fr 2fr 1fr', '1fr 4fr 2fr', '1fr 10fr 3fr'],
  '*': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'filter linear 200ms',
  },
  '.border': {
    borderLeft: '1px solid white',
  },
  '.playWrapper': {
    cursor: 'pointer',
    position: 'relative',
    maxHeight: '50px',
    img: {
      filter: 'invert(100%)',
    },
    ':hover img': {
      filter:
        'invert(86%) sepia(41%) saturate(487%) hue-rotate(52deg) brightness(98%) contrast(86%)',
    },
  },
  '.play': {
    height: '40%',
  },
  '.pause': {
    height: '25px',
  },
  '.socials a': {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 0.5rem',
  },
  '.socials img': {
    filter: 'invert(100%)',
    width: '20px',
    ':hover': {
      filter:
        'invert(86%) sepia(41%) saturate(487%) hue-rotate(52deg) brightness(98%) contrast(86%)',
    },
  },
})
