/** @jsxRuntime classic */
/* @jsx jsx */
import { jsx } from '@emotion/react';
import Header from './components/Header';
import BottomHeader from './components/BottomHeader';
import Teana from './components/Teana';
import Landing from './views/Landing';
import Listen from './views/Listen';
import Merch from './views/Merch';
import About from './views/About';
import Gallery from './views/Gallery';
import { useRef, useState } from 'react';

export default function App() {
  const landingRef = useRef(null);
  const aboutRef = useRef(null);
  const listenRef = useRef(null);
  const merchRef = useRef(null);

  const [openMenu, setOpenMenu] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);

  const scrollTo = where => {
    switch (where) {
      case 'landing':
        landingRef.current.scrollIntoView();
        break;
      case 'about':
        aboutRef.current.scrollIntoView();
        break;
      case 'listen':
        listenRef.current.scrollIntoView();
        break;
      case 'merch':
        merchRef.current.scrollIntoView();
        break;
      default:
        break;
    }
  };

  const togglePlaybackRate = newValue => {
    setPlaybackRate(newValue);
  };

  return (
    <div css={app}>
      <Header
        scrollTo={scrollTo}
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
      />
      <BottomHeader
        playbackRate={playbackRate}
        setPlaybackRate={togglePlaybackRate}
      />
      <Landing
        landingRef={landingRef}
        playbackRate={playbackRate}
        setPlaybackRate={togglePlaybackRate}
      />
      <Teana scrollTo={scrollTo} />
      <Gallery />
      <About aboutRef={aboutRef} />
      <Listen listenRef={listenRef} />
      <Merch merchRef={merchRef} />
    </div>
  );
}

const app = {
  section: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid white',
  },
};

// - responsiveness in landing for iPhone
