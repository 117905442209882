/** @jsxRuntime classic */
/* @jsx jsx */
import { jsx } from '@emotion/react'
import facepaint from 'facepaint'

// RESPONSIVENESS SETTINGS
const breakpoints = [800, 1100]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`))

export default function Listen(props) {
  const links = [
    'https://open.spotify.com/album/0wYk9gDKmOA3IB9L2mKHsK?si=gi-c3dmwQpGYU-LzsOU7VQ',
    'https://open.spotify.com/album/7ofVVErpVF9Xxugukh75NE?si=TqHWiZOSQf6544VToanRdw',
    'https://open.spotify.com/album/5NPo3VfEH6tb3vifMFd2Fb?si=TIQmJbM5RzCClT5nh-RtvA',
    'https://open.spotify.com/album/7akheN020VAaRvaJ7Grh0p?si=44IXdytbQDayx21ozwz7rg',
    'https://open.spotify.com/album/3eMs6mhw9TlATmppSEDJrQ?si=dGIxqZ2LRoa-r0D5k2g4gg',
    'https://open.spotify.com/album/4RlH7abSNKAIuH13hb4r75?si=zk1GhtBOSsembf_9fqwulg',
    'https://open.spotify.com/album/3gIMMeAllQOC340qqZe9EC?si=N6qHwDB2SbemwOL3_AsADw',
    'https://open.spotify.com/track/0lCluS7fsqt6SKiUBLT4xN?si=Sd0MQUEhTvmKiLXrTuh2Vw',
    'https://open.spotify.com/album/7ehGZLLXXjYI0sgvxlsWJI?si=6U2fCGsIQpeBbekfMuIytg',
  ]

  const renderCovers = () => {
    return [...Array(9).keys()].map((i) => {
      if (i === 8) {
        console.log(`/pictures/covers/${i}.gif`)
        return (
          <div key={i} css={coverStyle}>
            <img
              alt='band members'
              src={`/pictures/covers/${i}.gif`}
              style={{ position: 'relative', top: 0 }}
            />
            <div>
              <a
                href={links[i]}
                target='_blank'
                style={{ position: 'relative', top: 0 }}
                rel='noreferrer'
              >
                play on spotify
              </a>
            </div>
          </div>
        )
      } else
        return (
          <div key={i} css={coverStyle}>
            <img
              alt='band members'
              src={`/pictures/covers/${i}.jpg`}
              style={{ position: 'relative', top: 0 }}
            />
            <div>
              <a
                href={links[i]}
                target='_blank'
                style={{ position: 'relative', top: 0 }}
                rel='noreferrer'
              >
                play on spotify
              </a>
            </div>
          </div>
        )
    })
  }

  return (
    <div ref={props.listenRef} css={listenStyle}>
      <p>Listen</p>
      <div className='grid'>{renderCovers()}</div>
    </div>
  )
}

const listenStyle = mq({
  borderBottom: '1px solid white',
  textAlign: 'center',
  textTransform: 'uppercase',
  padding: '90px 3%',
  p: {
    marginBottom: '1.5rem',
  },
  '.grid': {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 auto',
    marginLeft: [0, 0, '1.5%'],
    justifyContent: ['center', 'center', 'flex-start'],
  },
})

const coverStyle = mq({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  border: '1px solid white',
  ':hover': {
    img: { display: 'none' },
    div: { display: 'flex' },
  },
  img: {
    width: ['80vw', '31vw', '18vw'],
  },
  div: {
    justifyContent: 'center',
    alignItems: 'center',
    width: ['80vw', '31vw', '18vw'],
    height: ['80vw', '31vw', '18vw'],
    display: 'none',
    boxSizing: 'border-box',
  },
  a: {
    color: 'inherit',
    transition: 'all 200ms linear',
    ':hover': {
      color: '#95E888',
    },
  },
})
