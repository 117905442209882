/** @jsxRuntime classic */
/* @jsx jsx */
import {jsx} from '@emotion/react';

export default function Button (props) {
  const buttonStyle = {
    borderRadius: '12px',
    border: 'none',
    padding: '8px 20px',
    fontSize: props.fontSize,
    whiteSpace: 'nowrap',
  };

  return <button css={buttonStyle}>{props.title}</button>;
}
