/** @jsxRuntime classic */
/* @jsx jsx */
import { jsx } from '@emotion/react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useState } from 'react'
import facepaint from 'facepaint'

// RESPONSIVENESS SETTINGS
const breakpoints = [700, 1100, 1300, 1500]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`))

export default function Merch(props) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1100 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1100, min: 700 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
    },
  }

  const texts = ['lighter', 't-shirt B', 't-shirt W', 'totebag']
  const links = [
    'https://teana.bandcamp.com/merch/kye-lighter',
    'https://teana.bandcamp.com/merch/t-shirt-ep-cover',
    'https://teana.bandcamp.com/merch/t-shirt-kill-your-ego',
    'https://teana.bandcamp.com/merch/kill-your-ego-totebag',
  ]

  const [hovered, setHovered] = useState([false, false, false, false])

  return (
    <div ref={props.merchRef} css={merchStyle}>
      <p>Merch</p>
      <Carousel
        responsive={responsive}
        showDots={false}
        arrows={true}
        autoPlaySpeed={3000}
        autoPlay={true}
        centerMode={false}
        className='slider'
        containerClass='container-with-dots'
        dotListClass='dots'
        draggable
        focusOnSelect={false}
        infinite
        itemClass='carousel-top'
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
      >
        {[...Array(4).keys()].map((i) => {
          return (
            <a
              href={links[i]}
              css={itemStyle}
              key={i}
              target='_blank'
              rel='noreferrer'
            >
              <img
                alt='merch'
                src={`/pictures/merch/${i}.png`}
                onMouseEnter={() => {
                  let copy = [...hovered]
                  copy[i] = true
                  setHovered(copy)
                }}
                style={{ display: hovered[i] ? 'none' : 'block' }}
              />
              <img
                alt='merch'
                src={`/pictures/merch/${i}h.png`}
                onMouseLeave={() => {
                  let copy = [...hovered]
                  copy[i] = false
                  setHovered(copy)
                }}
                style={{ display: !hovered[i] ? 'none' : 'block' }}
              />
              <p>{texts[i]}</p>
            </a>
          )
        })}
      </Carousel>
    </div>
  )
}

const CustomRightArrow = ({ onClick, ...rest }) => {
  return (
    <div className='cursorArrow' css={arrowStyleR} onClick={() => onClick()} />
  )
}

const CustomLeftArrow = ({ onClick, ...rest }) => {
  return (
    <div className='cursorArrowL' css={arrowStyleL} onClick={() => onClick()} />
  )
}

const merchStyle = mq({
  minHeight: '100vh',
  boxSizing: 'border-box',
  textAlign: 'center',
  textTransform: 'uppercase',
  padding: '100px 0',
})

const itemStyle = mq({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: ['80%', '90%', '100%'],
  margin: '0 auto',
  img: {
    border: '1px solid white',
    borderRadius: '100%',
    width: '120%',
    margin: '4rem 2rem 3rem',
  },
})

const arrowStyleR = {
  position: 'absolute',
  top: 0,
  right: 'calc(4% + 1px)',
  height: '2000px',
  width: '150px',
}

const arrowStyleL = {
  position: 'absolute',
  top: 0,
  left: 'calc(4% + 1px)',
  height: '2000px',
  width: '150px',
}
