/** @jsxRuntime classic */
/* @jsx jsx */
import { jsx, keyframes } from '@emotion/react'
import facepaint from 'facepaint'

// RESPONSIVENESS SETTINGS
const breakpoints = [600, 800, 1300, 1800]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`))

export default function Landing(props) {
  const logoStyle = {
    width: '100%',
    position: 'relative',
  }

  const landingStyle = mq({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100vw',
    boxSizing: 'border-box',
    img: {
      filter: 'invert(100%)',
      transition: 'filter linear 300ms',
      width: '100%',
    },
    '.wrapper': {
      padding: '10%',
    },
    '.blur': {
      width: '50vw',
      zIndex: 10,
      maxWidth: '350px',
      minWidth: '250px',
      height: logoStyle.height,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      transition: 'filter 500ms',
      '.teana_text': {
        position: 'absolute',
        bottom: '10%',
        width: '60%',
        marginLeft: '-3%',
      },
    },
    '.wrapper:hover .blur': {
      filter: 'blur(2px)',
    },
    '.blur:hover img': {
      filter: 'invert(100%) blur(5px)',
      animation: `${bounce} 1s ease infinite`,
    },
    '.test': {
      position: 'sticky',
      top: 0,
    },
  })

  const slowDownAudio = (val) => {
    props.setPlaybackRate(val)
  }

  const normalizeAudio = () => {
    props.setPlaybackRate(1)
  }

  return (
    <section ref={props.landingRef} css={landingStyle}>
      <div
        className='wrapper'
        onMouseLeave={normalizeAudio}
        onMouseEnter={() => slowDownAudio(0.8)}
      >
        <div className='blur'>
          <img
            alt='teana'
            src='/pictures/teana_logo.svg'
            onMouseEnter={() => slowDownAudio(0.5)}
          />
        </div>
      </div>
    </section>
  )
}

const bounce = keyframes`
  from, 0%, 30%, 50%, to {
    filter: invert(100%) blur(5px)
  }

  20%, 60% {
    filter: invert(100%) blur(6px)
  }
  
  40%, 90% {
    filter: invert(100%) blur(4px)
  }
`
