/** @jsxRuntime classic */
/* @jsx jsx */
import { jsx } from '@emotion/react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

export default function Gallery(props) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  return (
    <div ref={props.galleryRef} css={galleryStyle}>
      <Carousel
        responsive={responsive}
        showDots={false}
        arrows={true}
        autoPlaySpeed={3000}
        autoPlay={true}
        centerMode={false}
        className='slider'
        containerClass='container-with-dots'
        dotListClass='dots'
        draggable
        focusOnSelect={false}
        infinite
        itemClass='carousel-top'
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
      >
        {[...Array(4).keys()].map((i) => (
          <img key={i} alt='band members' src={`/pictures/${i}.jpg`} />
        ))}
      </Carousel>
    </div>
  )
}

const CustomRightArrow = ({ onClick }) => {
  return (
    <div className='cursorArrow' css={arrowStyleR} onClick={() => onClick()} />
  )
}

const CustomLeftArrow = ({ onClick }) => {
  return (
    <div className='cursorArrowL' css={arrowStyleL} onClick={() => onClick()} />
  )
}

const galleryStyle = {
  borderBottom: '1px solid white',
  'img, li': {
    width: '100vw',
    height: '100vh',
    objectFit: 'cover',
  },
}

const arrowStyleR = {
  position: 'absolute',
  top: 0,
  right: 'calc(4% + 1px)',
  height: '2000px',
  width: '150px',
}

const arrowStyleL = {
  position: 'absolute',
  top: 0,
  left: 'calc(4% + 1px)',
  height: '2000px',
  width: '150px',
}
