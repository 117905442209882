/** @jsxRuntime classic */
/* @jsx jsx */
import { jsx } from '@emotion/react'
import Button from './Button'
import facepaint from 'facepaint'
import { useTransition, animated } from 'react-spring'

// RESPONSIVENESS SETTINGS
const breakpoints = [800, 1100]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`))

export default function Header(props) {
  const DesktopHeader = () => {
    return (
      <header css={headerDesktopStyle} key='1'>
        <div onClick={() => props.scrollTo('about')} className='flex'>
          ABOUT
        </div>
        <div onClick={() => props.scrollTo('listen')} className='flex'>
          LISTEN
        </div>
        <div onClick={() => props.scrollTo('merch')} className='flex'>
          MERCH
        </div>
        <div />
        <Button title='BOOK NOW' fontSize='14px' />
      </header>
    )
  }

  const MobileHeader = ({ props }) => {
    const transition = useTransition(props.openMenu, {
      from: { y: -100, opacity: 0 },
      enter: { y: 0, opacity: 1 },
      leave: { y: -100, opacity: 0 },
    })

    const headerMobileStyle = mq({
      zIndex: 100000,
      position: 'fixed',
      top: 0,
      right: 0,
      display: props.openMenu ? 'none' : ['flex', 'none'],
      justifyContent: 'flex-end',
      height: 'fit-content',
      padding: '44px 40px 0 0',
      img: {
        filter: 'invert(100%)',
        width: '2rem',
        cursor: 'pointer',
      },
    })

    const menuStyle = mq({
      display: props.openMenu ? 'flex' : 'none',
      position: 'fixed',
      top: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'white',
      color: 'black',
      zIndex: 100000,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      div: {
        fontSize: '2.5rem',
        marginBottom: '2.5rem',
        cursor: 'pointer',
      },
    })

    const scrollTo = (where) => {
      props.setOpenMenu(false)
      props.scrollTo(where)
    }

    return [
      <header css={headerMobileStyle} key='3'>
        <img
          src='https://img.icons8.com/ios/50/000000/menu--v1.png'
          alt='burger'
          onClick={() => props.setOpenMenu(true)}
        />
      </header>,
      <div key='4'>
        {transition((style, item) =>
          item ? (
            <animated.div
              style={{ ...style, position: 'fixed', top: 0, zIndex: 100000 }}
            >
              <div css={menuStyle}>
                <div onClick={() => scrollTo('landing')} className='flex'>
                  HOME
                </div>
                <div onClick={() => scrollTo('about')} className='flex'>
                  ABOUT
                </div>
                <div onClick={() => scrollTo('listen')} className='flex'>
                  LISTEN
                </div>
                <div onClick={() => scrollTo('merch')} className='flex'>
                  MERCH
                </div>
              </div>
            </animated.div>
          ) : (
            ''
          )
        )}
      </div>,
    ]
  }

  return [
    <DesktopHeader key='1' />,
    <MobileHeader props={{ ...props }} key='2' />,
  ]
}

const headerDesktopStyle = mq({
  zIndex: 100000,
  position: 'fixed',
  top: 0,
  width: 'calc(100% - 106px)',
  maxWidth: '100vw',
  display: ['none', 'grid'],
  gridTemplateColumns: ['', '1fr 1fr 1fr 4fr 1fr', '1fr 1fr 1fr 8fr 1fr'],
  margin: ['', '44px 40px 0', '44px 53px 0'],
  fontSize: '14px',
  '.flex': {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    cursor: 'pointer',
  },
  button: {
    transition: 'all linear 200ms',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#95E888',
    },
  },
})
