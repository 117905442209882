/** @jsxRuntime classic */
/* @jsx jsx */
import { jsx } from '@emotion/react'
import facepaint from 'facepaint'

// RESPONSIVENESS SETTINGS
const breakpoints = [800, 1100]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`))

export default function About(props) {
  return (
    <div ref={props.aboutRef} css={aboutStyle}>
      <div className='wrapper'>
        <p>ABOUT</p>
        <p>
          Fresh Psychpop from{' '}
          <span>
            <a
              href='https://www.google.es/maps/place/Barcelona/@41.3926467,2.0701496,12z/data=!3m1!4b1!4m5!3m4!1s0x12a49816718e30e5:0x44b0fb3d4f47660a!8m2!3d41.3873974!4d2.168568?hl=es'
              target='_blank'
              rel='noreferrer'
            >
              Barcelona
            </a>
          </span>
          . Formed in 2017 by{' '}
          <span>
            <a
              href='https://www.instagram.com/fabio.numbela/'
              target='_blank'
              rel='noreferrer'
            >
              Fabio Numbela
            </a>
          </span>{' '}
          (Lead vocals/ Guitar),{' '}
          <span>
            <a
              href='https://www.instagram.com/alexruizter/'
              target='_blank'
              rel='noreferrer'
            >
              Alex Ruíz
            </a>
          </span>{' '}
          (Guitar and Keyboard),{' '}
          <span>
            <a
              href='https://www.instagram.com/alemany_ferran/'
              target='_blank'
              rel='noreferrer'
            >
              Ferrán Alemany
            </a>
          </span>{' '}
          (Drums) and{' '}
          <span>
            <a
              href='https://www.instagram.com/alexandregofas/'
              target='_blank'
              rel='noreferrer'
            >
              Alexandre Gofas
            </a>
          </span>{' '}
          (Bass).
        </p>
        <p>
          Their sound is greatly influenced by so many genres from bedroom pop
          to noise. None of this would be possible without their profound love
          for{' '}
          <span>
            <a
              href='https://open.spotify.com/artist/5INjqkS1o8h1imAzPqGZBb?si=Os8MibLJS_GbfuvzluHkdw'
              target='_blank'
              rel='noreferrer'
            >
              Tame Impala
            </a>
          </span>
          .
        </p>
        <p>
          Also, have a look to their{' '}
          <span>
            <a
              href='https://open.spotify.com/artist/3ceUUedlLTXTSc4TcPpWxm?si=lk0SwafRTyCH9dzkjDxuEA'
              target='_blank'
              rel='noreferrer'
            >
              monthly playlist
            </a>
          </span>
          … tuuuunes!
        </p>
      </div>
    </div>
  )
}

const aboutStyle = mq({
  height: '100vh',
  // height: 'calc(100vh - 130px)',
  borderBottom: '1px solid white',
  display: 'flex',
  alignItems: 'center',
  '.wrapper': {
    // position: 'sticky',
    // top: '25vh',
    margin: '0 auto 0',
    // margin: '90px auto 20vh',
    width: ['85%', '75%', '50%'],
    textAlign: 'center',
    textTransform: 'uppercase',
    // height: '50vh',
    p: {
      marginBottom: '1.5rem',
    },
  },
  a: {
    color: '#95E888',
  },
})
